// local server
// export const BASE_URL = 'http://192.168.0.106:8080';
// export const BASE_URL = 'http://192.168.0.112:8080';
// export const BASE_URL = 'https://admin.globalbusinessbd.com';
export const BASE_URL = 'https://admin.niraapod.com';




// server
// export const BASE_URL = 'http://127.0.0.1:8080';
//***********Obahe*************************** */
// export const BASE_URL = 'http://199.188.200.136';
// export const BASE_URL = 'https://admin.globalbusinessbd.com';





// product api
// export const PRODUCT_URL = BASE_URL + 'products/'

export const NO_TOKEN_BRANDS = BASE_URL + '/api/v1/no-token-brands/?size=24'
export const NO_TOKEN_SELLERS = BASE_URL + '/api/v1/no-token-sellers'
export const NO_TOKEN_SHIPPING_CLASS= BASE_URL + '/api/v1/no-token-shipping-class'
export const PRE_ORDER_ADVANCE_PRECHANTAGE = 50
// product api
export const PRODUCT_SEARCH = BASE_URL + '/api/v1/no-token-product-list/'
export const PRODUCT_URL = BASE_URL + '/api/v1/no-token-product-list/?size=18'
export const VIRTUAL_PRODUCT_URL = BASE_URL + '/api/v1/virtual-products/'
export const BUNDLE_PRODUCT_URL = BASE_URL + "/api/v1/no-token-bundle-product-list/?size=12"
export const BUNDLE_PRODUCT_REVIEW_URL = BASE_URL+'/api/v1/bundle-product-review/'
export const NO_TOKEN_CHILD_CATEGORY_URL = BASE_URL + '/api/v1/no-token-child-category/?size=12'
export const GET_ALL_CATEGORY_URL = BASE_URL + '/api/v1/categories'
export const NO_TOKEN_FEATURE_PRODUCT_URL = BASE_URL + '/api/v1/no-token-feature-product/?size=18'
export const NO_TOKEN_ON_SALE_PRODUCT_URL = BASE_URL + '/api/v1/no-token-on-sale-product/?size=12'
export const NO_TOKEN_BABY_CARE_TOY_PRODUCT_URL = BASE_URL + '/api/v1/no-token-product-list/?size=12&primary_categories__id=4'
export const NO_TOKEN_BEAUTY_AND_HEALTH_PRODUCT_URL = BASE_URL + '/api/v1/no-token-product-list/?size=12&primary_categories__id=5'
export const CATEGORY_URL = BASE_URL + '/api/v1/no-token-product-list/'
export const SLIDE_SETTING = BASE_URL + '/api/v1/no-token-slide-setting-list/'
export const SLIDE_RIGHT_SIDE_SETTING = BASE_URL + '/api/v1/no-token-slide-right-side-setting-list/'
export const BANNER_SETTING = BASE_URL + '/api/v1/banner-setting/'
export const USER_STAT_URL = BASE_URL + '/api/v1/user-stat/'
export const CUSTOMER_URL = BASE_URL + '/api/v1/customer/'
export const ORDER_CREATE = BASE_URL + '/api/v1/order-create/'
export const ORDERS_URL = BASE_URL + '/api/v1/orders/'
export const PAYMENT_METHOD_URL = BASE_URL + '/api/v1/payment_methods/'
export const PRODUCT_SEARCH_URL = BASE_URL + '/api/v1/no-token-product-search/'
export const PRODUCT_REVIEW_URL = BASE_URL + '/api/v1/product-review/'
export const CAMPAIGN_URL = BASE_URL + '/api/v1/no-token-campaign/'
export const LOGIN_URL = BASE_URL + '/api/auth/login/'
export const LOAD_USER_URL = BASE_URL + '/api/auth/user/'
export const USER_CREATE_URL = BASE_URL + '/api/auth/user-create/'
export const USER_SHIPPING_ADDRESS_URL = BASE_URL + '/api/auth/create-user-shipping-address/'
export const USER_BILLING_ADDRESS_URL = BASE_URL + '/api/auth/create-user-billing-address/'
export const REGISTER_URL = BASE_URL + '/api/auth/register/'
export const FOOTER_SOCIAL_MEDIA_URL = BASE_URL + '/api/v1/footer-social-media/'
export const FOOTER_LEFT_SECTION_URL = BASE_URL + '/api/v1/footer-left-section/'
export const SUBSCRIBE_URL = BASE_URL + '/api/auth/subscribers/'

export const BLOG_POST_URL = BASE_URL + '/api/v1/post-list/'
export const BLOG_TAG_URL = BASE_URL + '/api/v1/blog-tag/'
export const BLOG_SUB_CATEGORY_URL = BASE_URL + '/api/v1/blog-sub-category-list/'
export const COMMENTS_URL = BASE_URL + '/api/v1/comment/'
export const DISTRICT_URL = BASE_URL + '/api/v1/district/';
export const COPY_RIGHT_URL = BASE_URL + '/api/v1/copy-right/';
export const CONTACT_INFO_URL = BASE_URL + '/api/v1/contact-info/';
export const DASBOARD_STATISTICS = BASE_URL + '/api/v1/user-statistics/';
export const CUSTOMER_ORDERS_URL = BASE_URL + '/api/v1/customer-orders/'
export const LOGO_URL = BASE_URL + '/api/v1/logo/';
export const FOOTER_LOGO_URL = BASE_URL  + '/api/v1/footer-logo/';
export const FOOTER_PAYMENT_IMAGE_URL = BASE_URL+ '/api/v1/footer-payment-image/';
export const COUPON_CODE_URL = BASE_URL + '/api/v1/no-token-coupon-code/'

export const ABOUT_US_URL = BASE_URL + '/api/v1/about-us/'
export const TERMS_CONDITION_URL = BASE_URL+'/api/v1/terms-condition/'
export const SHIPPING_POLICY_URL = BASE_URL + '/api/v1/shipping-policy/'
export const PRIVACY_POLICY_URL = BASE_URL + '/api/v1/privacy-policy/'
export const RETURN_REFUND_POLICY_URL = BASE_URL + '/api/v1/return-refund-policy/'

export const FAQ_URL = BASE_URL + '/api/v1/faq/'
export const SUPPORT_TICKET_URL = BASE_URL + '/api/auth/support/'
export const CONTACT_MESSAGES_URL = BASE_URL + '/api/auth/contact-messages/'
export const SUPPORT_MESSAGES_URL = BASE_URL + '/api/auth/support-messages/'
export const NO_TOKEN_AGENT_URL = BASE_URL+ '/api/v1/no-token-agent/'
export const NO_TOEKN_SELLER_URL = BASE_URL + '/api/v1/no-token-seller/'